<template>


    <v-row>
        <v-col
                cols="12"
                sm="6"
        >
            <v-card
                    class="pa-2"
                    elevation="0"
            >
            <h2>{{header}}</h2>
                <v-card
                        class="mt-6 pa-2"
                        elevation="10"
                        outlined
                >


                    <v-list-item-group
                            shaped
                            color="primary"
                            v-model="selectedItem"
                    >
                        <v-list-item
                                v-for="(e,i) in arr"
                                :key="i"
                                @click="onNext(i)"
                        >
                            <v-list-item-icon>
                                <v-icon v-if="e.id=='Pay'"
                                        color="green"
                                >
                                    {{icon.pay}}
                                </v-icon>
                                <v-icon v-else
                                        color="green"
                                >
                                    {{icon.oms}}
                                </v-icon>

                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title v-text="e.name"></v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>
                </v-card>
            </v-card>
        </v-col>
        <v-col
                cols="12"
                sm="6"
        >
            <v-card
                    class="pa-2"
                    elevation="0"
            >
                <h2>Выбрано</h2>
                <v-card
                        class="mt-6 pa-2"
                        elevation="0"
                        outlined
                >


                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title v-html="LPU.name"></v-list-item-title>
                            <v-list-item-subtitle>{{LPU.address}}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>

                    <!--v-list-item>
                        <v-list-item-content>
                            <v-list-item-action>
                                <v-btn block
                                       @click.prevent="onCancel()">
                                    {{$data.captionCancel}}
                                </v-btn>
                            </v-list-item-action>
                        </v-list-item-content>
                    </v-list-item-->

                </v-card>
            </v-card>
        </v-col>
    </v-row>


</template>

<script>
    import {toBack, toNext} from '../components/app/js/globals'
    import {
        mdiCash100,
        mdiFileDocument
    } from '@mdi/js';
    import {postLog} from "../tools/post";

    export default {
        name: 'Fin',
        data: () => ({
            name: 'Fin',
            header: 'Выберите тип оплаты',
            log: true,
            selectedItem: null,
            //captionCancel: 'Отмена приема',
            icon: {
                oms: mdiFileDocument,
                pay: mdiCash100
            } ,

            arr: [
                {
                    id: 'Oms',
                    name: 'Прием ОМС',
                    opt: 'VT'
                },
                {
                    id: 'Pay',
                    name: 'Платный прием',
                    opt: 'VTP'
                }

            ]
        }),
        computed: {
            LPU() {
                return this.$store.getters.LPU
            },
            FIN() {
                return this.$store.getters.FIN
            },
            is_actual() {
                return (this.$store.getters.LPU.actual && this.$store.getters.LPU.actual === '1')
            }

        },
        async beforeMount() {
          try {
            if (this.is_actual) {

            } else {
                await toBack(this)
            }
            const _FIN = this.$store.getters.FIN
            this.selectedItem = (_FIN && typeof _FIN.selectedItem === 'number' ? _FIN.selectedItem : null)

            //подтвердить актуальность selectedItem при переходах!!!
            if(this.selectedItem && this.arr.length<this.selectedItem+1){
                this.$store.dispatch('SET_FIN_ACTUAL', '0')
            }


          }catch(e){
             alert(e)
             postLog(this.$store.getters.LPU.resturl, { alert: e, place: 'Fin#1' } )
          }
        },

        methods: {
            async onNext(i){
                try {
                    this.$store.dispatch('SET_FIN', {selectedItem: i, ...this.arr[i]})
                    await toNext(this)
                }catch(e){
                    alert(e)
                    postLog(this.$store.getters.LPU.resturl, { alert: e, place: 'Fin#2' } )
                }

            }/*,
            async onCancel() {
                await this.$router.push('/ccancel')
            }
            */


        }



    }
</script>
